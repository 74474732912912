import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";

function Delete({open, onClose, code}) {

    const onDelete = async (code) =>{
        await axios.delete('https://docon.gr/parametroi/kathgories-eidwn/' + code)
        .then(res=> {
            onClose();
            console.log(code);
        })
        .catch(err=>{console.log(err)})
    }
  return (
    <div className={`${open ? "fixed inset-0 flex p-5 justify-center justify-items-center overflow-y-auto bg-[#00000046] w-full h-screen" : " overflow-y-hidden max-h-0 invisible"}`}>
        <div className={`${open ?"w-[500px] h-[300px] flex flex-col  mt-20 bg-white rounded-md": "max-h-0 invisible"}`}>
            <div className="flex flex-row justify-between justify-items-between border-b shadow-md ">
            <h1 className="flex text-left p-4 rounded font-semibold text-xl ">Επιβεβαίωση διαγραφής</h1>
            <IoMdClose className="cursor-pointer flex text-3xl text-black mt-4 mr-2" onClick={()=>onClose()}/>
            </div>
            <div className="flex flex-col my-auto text-pretty">
            <p className=" flex px-4 font-normal mb-5 lg:px-8 md:px-8">Είστε βέβαιος για την οριστική διαγραφή της κατηγορίας;</p>
            <span className=" flex font-medium justify-center justify-items-center text-xl">{code}</span>
            </div>
        <div className="p-3 flex flex-row justify-center justify-items-center">
        <button className=" justify-items-end text-left justify-right mr-5 bg-[#a6acac] hover:bg-[#a6acaca3] text-white font-semibold py-2 px-4 border rounded" onClick={()=>onClose()}>
        Ακύρωση
        </button>
      <button className=" justify-items-end text-left justify-right bg-[#70b7c5] hover:bg-[#70b7c57a] text-white font-semibold py-2 px-4 border rounded" onClick={()=>onDelete(code)}>
        Διαγραφή
      </button>
      </div>
        </div>
    </div>
  )
}

export default Delete