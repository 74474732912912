import React from 'react'
import {useState ,useRef,useEffect} from 'react'
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Kwdikosinfo from '../../../../../components/info/parametroi/kathgorieseidwn/Kwdikosinfo';
import PerigrafhInfo from '../../../../../components/info/parametroi/kathgorieseidwn/PerigrafhInfo';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CodeExists from '../../../../../components/info/parametroi/kathgorieseidwn/CodeExists';


function Addnew({ open, onClose, ids}) {
  const [codeInfo,setcodeInfo] = useState(false);
  const [perigrafhInfo,setperigrafhInfo] = useState(false);
  const [code, setCode] = useState("");
  const [name,setName] = useState("");
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [data,setData] = useState();
 
  let menuRef = useRef();
  let perigrafhRef = useRef();
  useEffect(() => {

    let handler = (e) => {
      if(!menuRef?.current?.contains(e.target)){
        setcodeInfo(false);
      }
      
    }

    document.addEventListener("mousedown",handler);

  });

  useEffect(()=> {
    let handler = (e) => {
      
      if(!perigrafhRef?.current?.contains(e.target)){
        setperigrafhInfo(false);
      }
      
    }

    document.addEventListener("mousedown",handler);
   
  })

    const checkId = Object.values(ids).filter(item=>item.code===`${code}`).length>0;

    const onMyClose = ()=>{
      setCode('');
      setName('');
      onClose();
      setOpenError(false);
      
    }

  const submitClick = async()=>{
    console.log(checkId);
      if(!checkId){
        await axios.post('https://docon.gr/parametroi/kathgories-eidwn/', {name,code})
        .then(res=>{
          onMyClose();
          setOpenError(false);
          console.log(checkId);
        })
        .catch(err=>{console.log(err)});
      
      } else{
        console.log("error");
        setOpenError(true);
        console.log(checkId);
      }
     
    
       
  }

 

  return (
    <div className={`${open ? "fixed inset-0 flex p-5 justify-center justify-items-center overflow-y-auto bg-[#00000046] w-full max-h-screen" : "w-screen max-h-0 invisible"}`}>
      <div className={`${open ? "w-[700px] h-[400px] flex flex-col justify-center justify-items-center mt-20 bg-white rounded-md" : "max-h-0 invisible"}`}>
        <div className="flex flex-row justify-between justify-items-between border-b shadow-md ">
        <h1 className="flex text-left p-4 rounded font-semibold text-xl ">Νέα Κατηγορία</h1>
        <IoMdClose className="cursor-pointer flex text-3xl text-black mt-4 mr-2" onClick={()=>onMyClose()}/>
        </div>
      
      
      <div className="flex flex-col ml-10">
      <label className="flex flex-col justify-items-start justify-start text-left mt-10 ml-5 ">
      <span className="flex text-black text-[15px] font-medium mb-2">Κωδικός</span>
      <div className="flex flex-row" ref={menuRef}>
      <div className="flex flex-col"></div>
      <input type="number" value={code} onWheel={ event => event.currentTarget.blur() } onChange={e => setCode(e.target.value)} className="px-2 flex-row h-7 w-80 text-sm bg-slate-50 border-2 border-gray-300 ml-3 outline-none rounded-md  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"/>
      <AiOutlineExclamationCircle className="hover:text-slate-300 cursor-pointer mt-1 ml-2" onClick={()=>setcodeInfo(!codeInfo)}/>
      <Kwdikosinfo flag={codeInfo}/>
      <div className="flex">
      <CodeExists open={openError}/>
      </div>
      
      </div>
      </label>
      <label className="flex flex-col justify-items-start justify-start text-left mt-10 ml-5">
      <span className="flex text-black text-[15px] font-medium mb-2">Περιγραφή</span>
      <div className="flex flex-row " ref={perigrafhRef}>
      <input type="text"  value={name} maxLength="50" onChange={e=> setName(e.target.value)} className=" px-2 flex h-7 w-80 text-sm bg-slate-50 border-2 border-gray-300 ml-3 outline-none rounded-md "/>
      <AiOutlineExclamationCircle className="hover:text-slate-300 cursor-pointer mt-1 ml-2"  onClick={()=>setperigrafhInfo(!perigrafhInfo)}/>
      <PerigrafhInfo flag={perigrafhInfo}/>
      </div>
      </label>
      </div>
      

      <div className="p-3 flex flex-row justify-end justify-items-end mt-10">
      <button className=" justify-items-end text-left justify-right mr-5 bg-[#a6acac] hover:bg-[#a6acaca3] text-white font-semibold py-2 px-4 border rounded" onClick={()=>onMyClose()}>
        Ακύρωση
      </button>
      <button className=" justify-items-end text-left justify-right bg-[#1a97af] hover:bg-[#70b7c57a] text-white font-semibold py-2 px-4 border rounded" onClick={()=>submitClick()}>
        Καταχώρηση
      </button>
      </div>
      </div>
        
        
    </div>
  )
}

export default Addnew