import React from 'react'
import { SideMenu } from './SideMenu'
import { BsArrowLeftCircleFill } from "react-icons/bs"
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from "react-icons/md";
import {useState} from 'react'
import { AiTwotoneCloud } from "react-icons/ai";
import {Link, NavLink} from 'react-router-dom';
import { IoMdClose } from "react-icons/io";
import MobileRendersubmenu from './MobileRendersubmenu'

function MobileMenu({ openMobile, onClose}) {
    const [open,setOpen]= useState(true);
    const [openSubmenu, setOpenSubmenu] = useState(false);
  
    const [toggle, setToggle] = useState(null);
    
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
    const [isSubMenuOpen3, setIsSubMenuOpen3] = useState(false);
    const [headerPath,setHeaderPath] = useState(null);
    const setHeader = (menu) => {
      setHeaderPath(menu);
     
    }
    const clickHandler = (menu) => {
        console.log(`clicked`)  ;
        setToggle(menu);
        if(menu===4){
          setIsSubMenuOpen(!isSubMenuOpen);
        }
        if(menu===5){
          setIsSubMenuOpen2(!isSubMenuOpen2);
        }
        if(menu===6){
          setIsSubMenuOpen3(!isSubMenuOpen3);
        }
        
        setHeader(menu);
        
       
      }

      

  return (
    
    <div className={`${openMobile ? "fixed inset-0 flex p-5 bg-[#00000046] w-full max-h-screen items-start justify-items-start justify-start":"w-screen max-h-0 invisible "}`}>
    <div className="w-screen mr-2 h-screen flex flex-col items-start justify-items-start justify-start bg-[#1a97af] duration-100 translate-x-0 overflow-y-auto  transition peer-checked:translate-x-0">
        <div 
        className="flex flex-row my-8 justify-between w-full">
        <div className="flex w-full">
        <AiTwotoneCloud className="text-white bg-[#1a97af] rounded block float-left text-4xl mr-3 ml-3 "/>
        <h1 className= "text-white origin-left text-1xl font-medium mt-1 duration-200 flex">Timologise.gr</h1>
        </div>
       <div className="flex mr-5 my-auto">
       <IoMdClose className="size-5 text-slate-300" onClick={()=>onClose()}/>
       </div>
       
      </div>
      <div>
      <ul
        className="pt-2 flex-col border-t border-white w-screen">
        {SideMenu.map((menu,index) => (
        <React.Fragment key = {index}>
        <li className="text-white text-sm block items-start gap-x-3 cursor-pointer p-2
         rounded-md mt-2 hover:text-slate-200 w-40"
         >
          <span className="text-sm float-left mt-1 mr-2 ">
          {menu.icon}
          </span>
            
            <NavLink to ={menu.path} className ="text-base font-medium duration-200 flex flex-row justify-between" onClick={()=>clickHandler(index)}>
              {menu.title}
              {menu.submenu ? <MdOutlineKeyboardArrowRight className={`text-md float-right flex mt-1${index===4 && isSubMenuOpen && "rotate-90"} ${index===5 && isSubMenuOpen2 && "rotate-90"} ${index===6 && isSubMenuOpen3 && "rotate-90"}`}  /> : null }
            </NavLink>
            {menu.submenu && index===4 ? <MobileRendersubmenu sideMenu={menu.submenu} isOpen={isSubMenuOpen} onClose={onClose}/> : null}
            {menu.submenu && index===5 ? <MobileRendersubmenu sideMenu={menu.submenu} isOpen={isSubMenuOpen2} onClose={onClose}/> : null}
            {menu.submenu && index===6 ? <MobileRendersubmenu sideMenu={menu.submenu} isOpen={isSubMenuOpen3} onClose={onClose}/>: null}
        </li>
        </React.Fragment>
      ))}

    </ul>
    </div>


    </div>
    </div>
  )
}

export default MobileMenu