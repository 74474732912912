import React from 'react'

function Kwdikosinfo({flag}) {
    if(!flag) return null;

  return (
    <div className="bg-white absolute z-10 min-w-30 px-3 py-2 shadow-sm float-end lg:ml-[18%] lg:mt-[2%] md:ml-[18%] md:mt-[6%] sm:mt-[8%] sm:ml-[25%] xs:ml-[15%] xs:mt-[15%] border-2 border-slate-300 rounded-sm">
        <p className="text-xs">Επιτρέπονται μόνο αριθμιτικοί χαρακτήρες.</p>
    </div>
  )
}

export default Kwdikosinfo