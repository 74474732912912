import React from 'react'
import axios from 'axios'
import {useState, useEffect} from 'react'


function StoixeiaEtaireias() {
  const [data,setData] = useState([]);
  const [epwnhmia, setEpwnhmia] = useState('');
  const [drasthriothta,setDrasthriothta] = useState('');
  const [diakritikosTitlos, setDiakritikosTitlos] = useState('');
  const [polh,setPolh] = useState('');
  const [afm,setAfm] = useState('');
  const [dieuthinsi,setDieuthinsi] = useState('');
  const [arithmos,setArithmos] = useState('');
  const [tk,setTk] = useState('');
  const [doy,setDoy] = useState('');
  const [gemh,setGemh] = useState('');
  const [mhtrow,setMhtrow] = useState('');
  const [thlwfwno,setThlefwno] = useState('');
  const [kinhto,setKinhto] = useState('');
  const [email,setEmail] = useState('');
  const [logo,setLogo] = useState('');
  const [trapezes,setTrapezes] = useState('');
  const [parathrhseis, setParathrhseis] = useState('');
  const [taxisUser,setTaxisUser] = useState('');
  const [taxisPass, setTaxisPass] = useState('');
  const [mydataUser, setMydataUser] = useState('');
  const [mydataPass, setMydataPass] = useState('');

  const getTableData = async () => {

    await axios.get('https://docon.gr/parametroi/stoixeia-etaireias/')
    .then(res=> setData(res.data))
    .catch(err=> console.log(err.response.data));
    
  }
  const onSubmitForm = ()=>{

  }

useEffect(()=>{

  getTableData();
  if(data!=null){
    Object.values(data).map((d,i)=>{
      setEpwnhmia(d.epwnhmia);
      setDrasthriothta(d.drasthriothta);
      setDiakritikosTitlos(d.diakritikosTitlos);
      setPolh(d.polh);
      setAfm(d.afm);
      setDieuthinsi(d.dieuthinsi);
      setArithmos(d.arithmos);
      setTk(d.tk);
      setDoy(d.doy);
      setGemh(d.gemh);
      setMhtrow(d.mhtrow);
      setThlefwno(d.thlwfwno);
      setKinhto(d.kinhto);
      setEmail(d.email);
      setLogo(d.logo);
      setTrapezes(d.trapezes);
      setParathrhseis(d.parathrhseis);
      setTaxisUser(d.taxisUser);
      setTaxisPass(d.taxisPass);
      setMydataPass(d.mydataPass);
      setMydataUser(d.mydataUser);
    })
  }
},[])


  return (
     <div className=" shadow-md overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md">
      <div className="flex flex-col border-[#d1d3e2] border-b shadow-md rounded-md">
      <h1 className="lg:text-2xl text-[#3a3b45] font-semibold flex justify-center">Στοιχεία Εταιρείας</h1>
      <span className="flex text-center font-normal lg:text-1xl md:text-sm sm:text-sm justify-center mt-3 mb-3">Παράμετροι</span>
      </div>
      
      <div className="flex-row mt-10 hidden md:flex lg:flex">
      <div className="flex flex-col w-[40%]">
          <label className="flex flex-row justify-items-start justify-left text-left mt-5">
          <span className="flex text-black text-[15px] font-normal mb-2 mr-10 bg-slate-200 rounded-md py-2 px-8 shadow-md">Επωνυμία:</span>
          </label>
          <label className="flex flex-row justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal mb-2 mr-10 bg-slate-200 py-2 px-8 rounded-md shadow-md">Δραστηριότητα επιχείρησης:</span>
          </label>
          <label className="flex flex-row justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal mb-2 mr-10 bg-slate-200 rounded-md py-2 px-8 shadow-md">Διακριτικός Τίτλος:</span>
          </label>
          <label className="flex flex-row justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal mb-2 mr-10 bg-slate-200 rounded-md py-2 px-8 shadow-md">Πόλη:</span>
          </label>
          <label className="flex flex-row justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal mb-2 mr-10 bg-slate-200 rounded-md py-2 px-8 shadow-md">ΑΦΜ:</span>
          </label>
          <label className="flex flex-row justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal mb-2 mr-10 bg-slate-200 rounded-md py-2 px-8 shadow-md">Διεύθυνση:</span>
          </label>
          </div>
          <div className="flex-2 flex-col w-full">
          <input type="text" value={epwnhmia} onChange={e=>setEpwnhmia(e.target.value)} placeholder="Η επωνυμία της επιχείρησης" className=" px-3 flex h-9 w-full text-sm mt-5 bg-white focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-2 border-[#d1d3e2] ml-3 outline-none rounded-md " required />
          <input type="text" value={drasthriothta} onChange={e=>setDrasthriothta(e.target.value)} placeholder="Η δραστηριότητα της επιχείρησης"className="px-3 flex h-9 w-full mt-10 text-sm bg-white  border-2 border-[#d1d3e2] focus:outline-none focus:border-[#70b7c5] focus:shadow-md ml-3 outline-none rounded-md "required />
         
          <input type="text" value={diakritikosTitlos} onChange={e=>setDiakritikosTitlos(e.target.value)} placeholder="Ο διακριτικός τίτλος" className=" px-3 flex h-9 w-full text-sm mt-10 bg-white border-2  focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          <input type="text" value={polh} onChange={e=>setPolh(e.target.value)} placeholder="Η πόλη που δραστηριοποιείται η επιχείρηση" className=" px-3 flex h-9 w-full text-sm mt-10 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " /> 
          <input type="text" value={afm} onChange={e=>setAfm(e.target.value)} placeholder="Το ΑΦΜ της επιχείρησης" className=" px-3 flex h-9 w-full text-sm mt-10 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          <div className="flex flex-row w-full">
          <input type="text" value={dieuthinsi} onChange={e=>setDieuthinsi(e.target.value)} placeholder="Η διεύθυνση της επιχείρησης" className=" px-3 flex-auto h-9 w-[60%] text-sm mt-10 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          <input type="number" value={arithmos} onChange={e=>setArithmos(e.target.value)} placeholder="Αριθμός" onWheel={ event => event.currentTarget.blur() } className=" px-3 flex-auto h-9 w-[25%] text-sm mt-10 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-7 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" />
          <input type="number" value={tk} onChange={e=>setTk(e.target.value)} placeholder="ΤΚ." onWheel={ event => event.currentTarget.blur() } className=" px-3 flex-auto h-9 w-[25%] text-sm mt-10 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-7 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" />
          </div>
          </div>
          
      </div>
      <div className="flex-col mt-10 lg:hidden md:hidden">
      
          <label className="flex flex-col justify-items-start justify-left text-left mt-5">
          <span className="flex text-black text-[15px] font-normal py-2">Επωνυμία:</span>
          <input type="text" value={epwnhmia} onChange={e=>setEpwnhmia(e.target.value)} placeholder="Η επωνυμία της επιχείρησης" className=" px-3 flex h-9 w-full text-sm mt-2 bg-white focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-2 border-[#d1d3e2] ml-3 outline-none rounded-md " required />
          </label>
          <label className="flex flex-col justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal py-2">Δραστηριότητα:</span>
          <input type="text" value={drasthriothta} onChange={e=>setDrasthriothta(e.target.value)} placeholder="Η δραστηριότητα της επιχείρησης"className="px-3 flex h-9 w-full text-sm bg-white  border-2 border-[#d1d3e2] focus:outline-none focus:border-[#70b7c5] focus:shadow-md ml-3 outline-none rounded-md "required />
          </label>
          <label className="flex flex-col justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal py-2">Διακριτικός Τίτλος:</span>
          <input type="text" value={diakritikosTitlos} onChange={e=>setDiakritikosTitlos(e.target.value)} placeholder="Ο διακριτικός τίτλος" className=" px-3 flex h-9 w-full text-sm  bg-white border-2  focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          </label>
          <label className="flex flex-col justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal py-2">Πόλη:</span>
          <input type="text" value={polh} onChange={e=>setPolh(e.target.value)} placeholder="Η πόλη που δραστηριοποιείται η επιχείρηση" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " /> 
          
          </label>
          <label className="flex flex-col justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal py-2">ΑΦΜ:</span>
          <input type="text" value={afm} onChange={e=>setAfm(e.target.value)} placeholder="Το ΑΦΜ της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          </label>
          <label className="flex flex-col justify-items-start justify-left text-left mt-8">
          <span className="flex text-black text-[15px] font-normal py-2">Διεύθυνση:</span>
          </label>
          <div className="flex flex-row w-full">
          <input type="text" value={dieuthinsi} onChange={e=>setDieuthinsi(e.target.value)} placeholder="Διεύθυνση" className=" px-3 flex-auto h-9 w-[60%] text-sm bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          <input type="number" value={arithmos} onChange={e=>setArithmos(e.target.value)} placeholder="Αριθμός" onWheel={ event => event.currentTarget.blur() } className=" px-1 flex-auto h-9 w-[25%] text-sm  bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" />
          <input type="number" value={tk} onChange={e=>setTk(e.target.value)} placeholder="ΤΚ." onWheel={ event => event.currentTarget.blur() } className=" px-1 flex-auto h-9 w-[25%] text-sm  bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" />
          </div>
          </div>
          
          
          
         
          
          
          
          
          
      
         
          
</div>


     
  )
}

export default StoixeiaEtaireias