import React from 'react'
import { IoLogoEuro } from "react-icons/io";
import { CiMenuKebab } from "react-icons/ci";

function Sales() {
  return (

    
    <div className="flex flex-col justify-between w-full md:w-auto h-48 bg-white p-4 rounded-md mr-5 sm:mb-5 md:mb-5">
      <div className="flex w-full items-center justify-between">
          <div className="text-4xl text-slate-400">
            <IoLogoEuro />
          </div>
          <div className="flex items-center justify-center w-10 h-10 text-slate-400 bg-white rounded-full">
            <CiMenuKebab className="text-2xl cursor-pointer  hover:text-slate-700 "/>
          </div> 
      </div>
        <div className="font-extrabold text-4xl sm:text-2xl lg:text-xl text-slate-400">
            €50.000
          </div>
        <div className="flex items-start justify-start">
          <span className="text-sm text-slate-400">Πωλήσεις</span>
          </div>
    </div>
  

  )
}

export default Sales