import React from 'react'
import { IoIosClose } from "react-icons/io";

function CodeExists({ open }) {
 
    if(!open) return null;
    return (
    <div className="flex flex-row">
      <IoIosClose className="text-red-400 font-medium flex ml-2"/>
      <p className="text-red-500 font-medium flex">Ο κωδικός δεν είναι μοναδικός !</p>
    </div>
  )
}

export default CodeExists