import React from 'react'
import {useState} from 'react'
import {NavLink} from 'react-router-dom';

function HeaderDropMenu({open}) {

    const HeaderMenu = [

        {
        title: "Εισαγωγή Παραστατικού",
        path: "/parastatika",
        cName: "nav-text",
        },
        {
        title: "Εισαγωγή Πελάτη",
        path:"/pelates",
        cName: "nav-text",
        },
        {
        title: "Εισαγωγή Είδους / Υπηρεσίας",
        path: "/eidh-yphresies",
        cName: "nav-text",
        },
        {
        title: "Είσπραξη Πελάτη",
        path: "/pelates",
        cName: "nav-text",
        },
        {
         title: "Ανανέωση Συνδρομής",
        path: "/eshop/ananewsh-sindromis",
        cName: "nav-text",
        },
];



  return (
    <div className="absolute z-10 w-[90%] lg:w-60 md:w-40 shadow-sm float-end lg:ml-[1%] lg:mt-[3%] md:ml-[3%] md:mt-[8%] mt-[16%] mr-[8%]">
    <ul className={`${open? "pt-1 flex-col bg-white": "max-h-0 invisible"}  rounded-md transition-all duration-200 ease-in-out overflow-hidden`}> 
    {HeaderMenu.map((menu,index) => (
        <React.Fragment key = {index}>
        <li className="text-[#3a3b45] text-xs flex items-center gap-x-2 cursor-pointer p-2
         rounded-md my-2 hover:bg-slate-100 font-normal py-1 px-6"
         >
        <NavLink to={menu.path} className ="font-medium duration-200">
            {menu.title}
        </NavLink>

         </li>


        </React.Fragment>
    ))}
    
    </ul>
    </div>
  );
}

export default HeaderDropMenu