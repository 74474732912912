import React from 'react'
import { BsArrowLeftCircleFill } from "react-icons/bs"
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowDown } from "react-icons/md";
import {useState} from 'react'
import { AiTwotoneCloud } from "react-icons/ai";
import {Link, NavLink} from 'react-router-dom';
import { SideMenu } from '../components/menus/SideMenu';
import Rendersubmenu from './Rendersubmenu';
import HamburgerMenu from '../components/menus/HumbergerMenu';

function MenuItems() {
  const [open,setOpen]= useState(true);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const [toggle, setToggle] = useState(null);
  
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
  const [isSubMenuOpen3, setIsSubMenuOpen3] = useState(false);
  
  const [headerPath,setHeaderPath] = useState(null);
  const setHeader = (menu) => {
    setHeaderPath(menu);
    
  }

  const clickHandler = (menu) => {
    console.log(`clicked`)  ;
    setToggle(menu);
    if(menu===4){
      setIsSubMenuOpen(!isSubMenuOpen);
    }
    if(menu===5){
      setIsSubMenuOpen2(!isSubMenuOpen2);
    }
    if(menu===6){
      setIsSubMenuOpen3(!isSubMenuOpen3);
    }
    
    setHeader(menu);
    
   
  }
  const rotate = "rotate-90";
  

  return (
    
    <div 
      className="bg-[#1a97af] p-5 pt-8 w-55 duration-200 hidden md:block lg:block"
    >
     
         <div 
        className="flex">
        <AiTwotoneCloud className="text-white bg-[#1a97af] rounded block float-left text-4xl mr-2 "/>
        <h1 className= "text-white origin-left text-1xl font-medium mt-1 duration-200 ">Timologise.gr</h1>
      </div>
      <div>
      <ul
        className="pt-2 flex-col border-t border-white ">
        {SideMenu.map((menu,index) => (
        <React.Fragment key = {index}>
        <li className="text-white text-sm block items-center gap-x-3 cursor-pointer p-2
         rounded-md mt-2 hover:text-slate-200 "
         >
          <span className="text-1xl float-left mt-1 mr-2 ">
          {menu.icon}
          </span>
            
            <NavLink to ={menu.path} className ="text-base font-medium duration-200" onClick={()=>clickHandler(index)}>
              {menu.title}
              {menu.submenu ? <MdOutlineKeyboardArrowRight className={`text-2xl float-right mt-1 ${index===4 && isSubMenuOpen && "rotate-90"} ${index===5 && isSubMenuOpen2 && "rotate-90"} ${index===6 && isSubMenuOpen3 && "rotate-90"}`}  /> : null }
            </NavLink>
            {menu.submenu && index===4 ? <Rendersubmenu sideMenu={menu.submenu} isOpen={isSubMenuOpen} /> : null}
            {menu.submenu && index===5 ? <Rendersubmenu sideMenu={menu.submenu} isOpen={isSubMenuOpen2} /> : null}
            {menu.submenu && index===6 ? <Rendersubmenu sideMenu={menu.submenu} isOpen={isSubMenuOpen3} />: null}
        </li>
        </React.Fragment>
      ))}

    </ul>
    </div>
    </div>
  );
};

export default MenuItems;
