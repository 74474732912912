import React from 'react'
import { CiMenuKebab } from "react-icons/ci";
import { LiaFileInvoiceSolid } from "react-icons/lia";
function InvoiceNums() {
    return (

        
        <div className="flex flex-col justify-between w-full md:w-auto h-48 bg-white p-4 rounded-md mr-5 sm:mb-5 md:mb-5">
          <div className="flex w-full items-center justify-between">
              <div className="text-4xl text-slate-400">
               <LiaFileInvoiceSolid />
              </div>
              <div className="flex items-center justify-center w-10 h-10 text-slate-400 bg-white rounded-full">
                <CiMenuKebab className="text-2xl cursor-pointer  hover:text-slate-700 "/>
              </div> 
          </div>
            <div className="font-extrabold text-4xl sm:text-2xl lg:text-xl text-slate-400">
                1.000                
              </div>
            <div className="flex items-start justify-start">
              <span className="text-sm text-slate-400">Παραστατικά</span>
              </div>
        </div>
      
    )
}
export default InvoiceNums