import React from 'react'
import { TiThMenu } from "react-icons/ti";
import { useState } from 'react';
import  MobileMenu  from "./MobileMenu"

function HumbergerMenu() {
  const [openMobileMenu,setOpenMobileMenu] = useState(false);
  const onClose = ()=>{
    setOpenMobileMenu(false);
  }
  return (
    <div className=" block md:hidden lg:hidden justify-center justify-items-center mr-4 mt-5 max-w-0 max-h-0">
      <TiThMenu className="my-auto text-2xl sm:text-1xl md:text-2xl text-[#1a97af] " onClick={()=>setOpenMobileMenu(!openMobileMenu)}/>
      <div>
        <MobileMenu openMobile={openMobileMenu} onClose={onClose}/>
      </div>
    </div>
    
    
  )
}

export default HumbergerMenu