import './App.css';
import {useState} from 'react'
import MainActivity from './screens/activities/MainActivity';
import {BrowserRouter, Routes, Route} from  'react-router-dom';
import MenuItems from './screens/MenuItems';

import MainScreen from './screens/MainScreen';
import Parastatika from './screens/activities/Parastatika';
import EidhYphresies from './screens/activities/EidhYphresies';
import Anafores from './screens/activities/Anafores';
import Pelates from './screens/activities/Pelates';
import Eshop from './screens/activities/Eshop';
import Parametroi from './screens/activities/Parametroi';
import Statistika from'./screens/activities/anafores/Statistika';

import HmerologioPwlhsewn from'./screens/activities/anafores/HmerologioPwlhsewn';
import ProvlepshFpa from'./screens/activities/anafores/ProvlepshFpa'; 
import ExagoghArxeiouLogisth from'./screens/activities/anafores/ExagoghArxeiouLogisth'; 
import PwlhseisAnaEidos from'./screens/activities/anafores/PwlhseisAnaEidos';
import SysxetishMyData from'./screens/activities/anafores/SysxetishMyData'; 
import YpoloipaPelatwn from'./screens/activities/anafores/YpoloipaPelatwn';

import AgoraMeParoxo from './screens/activities/eshop/AgoraMeParoxo';
import AgoraMePos from './screens/activities/eshop/AgoraMePos';
import AgoraMobile from './screens/activities/eshop/AgoraMobile';
import AgoraSMSEmail from './screens/activities/eshop/AgoraSMSEmail';
import AnanewshSyndromhs from './screens/activities/eshop/AnanewshSyndromhs';

import ElegxosSyndromwn from './screens/activities/parametroi/ElegxosSyndromwn';
import GenikesParametroi from './screens/activities/parametroi/GenikesParametroi';
import KathgoriesEidwn from './screens/activities/parametroi/KathgoriesEidwn';
import ParametroiParoxwn from './screens/activities/parametroi/ParametroiParoxwn';
import ParametroiPos from './screens/activities/parametroi/ParametroiPos';
import ParametroiTypoiParastatikwn from './screens/activities/parametroi/RythmiseisParastatikwn';
import StoixeiaEtaireias from './screens/activities/parametroi/StoixeiaEtaireias';
  
  

function App() {
const [element,setElement] = useState("Αρχική");
  return (
    <BrowserRouter>
    <div className="flex flex-row bg-slate-200 h-full">
      <MenuItems/>
      <MainScreen /> 
    </div>
    </BrowserRouter>
    
  );
}

export default App;
