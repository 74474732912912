import React from 'react'
import { RxDashboard} from 'react-icons/rx';
import {useState, useEffect, useRef} from 'react';
import {IoNotificationsOutline} from 'react-icons/io5';
import { BiSolidPlusSquare } from "react-icons/bi";
import * as AiIcons from "react-icons/ai";
import HeaderDropMenu from '../components/menus/HeaderDropMenu';
import { PiPlusSquare } from "react-icons/pi";
import HamburgerMenu from '../components/menus/HumbergerMenu';


function Header({element}) {

  const [open,setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {

    let handler = (e) => {
      if(!menuRef?.current?.contains(e.target)){
        setOpen(false);
      }
      
    }

    document.addEventListener("mousedown",handler);

  });
  
  return (
    <div className="flex justify-between py-2 px-5 bg-white ">
      
       
        
      <div className="flex flex-row cursor-pointer justify-start justify-items-start"  ref={menuRef} >
      <HamburgerMenu />
      <BiSolidPlusSquare className="p-2 text-6xl rounded-1g text-[#1a97af] hover:text-[#1a96af9a]" onClick={()=>setOpen(!open)}/>
      <HeaderDropMenu open={open}/>
      </div>
      
      
      
      
      <div className="flex my-auto">
        <RxDashboard className="p-2 text-4xl rounded-1g cursor-pointer text-slate-400  hover:text-slate-700 "/>
        <IoNotificationsOutline className="p-2 text-4xl rounded-1g cursor-pointer text-slate-400 hover:text-slate-700"/>
      </div>
      
    </div>
  )
}

export default Header