import React from 'react'
import Addnew from './Modals/kathgorieseidwn/Addnew'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import Delete from './Modals/kathgorieseidwn/Delete'
import Update from './Modals/kathgorieseidwn/Update'
import { PencilSquareIcon ,ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid'


function KathgoriesEidwn() {

  const [openCategory,setopenCategory]= useState(false);
  const [addCategory,setaddCategory] = useState([]);
  const [deleteRow, setDeleteRow] = useState();
  const [updateRow,setUpdateRow] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal,setOpenUpdateModal] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [data,setData] = useState([]);
  const updateRef = useRef();

  const handleClick = () =>{
    setopenCategory(!openCategory);
    getTableData();
    console.log(data);
    setUpdateRow('');
  }

  const deleteThisRow = (item,openDeleteModal)=>{
    setDeleteRow(item);
    console.log(deleteRow);
    setOpenDeleteModal(!openDeleteModal);
  }


const handleDeleteClick = ()=>{
  setOpenDeleteModal(!openDeleteModal);
  getTableData();
}
const updateThisRow = (item,openUpdateModal) =>{
  setUpdateRow(item)
  console.log(updateRow);
  setOpenUpdateModal(!openUpdateModal);
}

useEffect(()=>{

},[setUpdateRow])

const handleUpdateClick = ()=>{
  setOpenUpdateModal(!openUpdateModal);
  getTableData();
}
  useEffect(() => {
    document.body.style.overflow = openCategory ? "hidden" : "unset";
  }, [setopenCategory]);

const getTableData = async () => {

  await axios.get('https://docon.gr/parametroi/kathgories-eidwn/')
  .then(res=> setData(res.data))
  .catch(err=> console.log(err.response.data));
  
}
useEffect(()=>{
  console.log(data);
 getTableData();
},[])
  

  return (
    <div className="overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md ">
      <h1 className="lg:text-2xl text-[#3a3b45] font-semibold">Κατηγορίες Ειδών</h1>
      <span className="flex text-center font-normal lg:text-1xl md:text-sm sm:text-sm justify-center mt-3">Εδώ καταχωρείτε τις κατηγορίες για τα είδη σας.</span>
      <div className="p-8 justify-end text-right">
      <button className="justify-items-end text-left justify-right bg-[#1a97af] hover:bg-[#70b7c57a] text-white font-semibold py-2 px-4 border rounded" onClick={()=>setopenCategory(!openCategory)}>
        Νέα Καταχώρηση
      </button>
      <Addnew open={openCategory} onClose={handleClick} ids={data}/>
      <div className="flex justify-center justify-items-center mt-10">
      <table className="w-full shadow-md sm:rounded-lg mt-3 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="shadow-md rounded-md text-xs text-gray-700 bg-slate-300 uppercase dark:bg-gray-700 dark:text-gray-400">
        <tr className="bg-slate-200">
            <th className="px-6 py-3">Κωδικος</th>
            <th className="px-6 py-3">Περιγραφη κατηγοριας</th>
        </tr>
        </thead>
        <tbody>
          {Object.values(data).map((d,i)=>{
            
            return(
              <tr className="border-b" key={i} >
              <td className="px-6 py-4">{d.code}</td>
              <td className="px-6 py-4">{d.name}</td>
              
              <td className="float-right p-2 flex flex-row">
                <button ref={updateRef} className=" flex h-10 mr-4 p-2 w-30 rounded border-1 border-slate-200 font-medium text-white  hover:bg-[#70b7c57a] bg-[#1a97af] " onClick={()=>updateThisRow(d,openUpdateModal)}>
                  <PencilSquareIcon className="size-6"/>
                  <span className="text-1xl hidden lg:flex md:flex">Επεξεργασία</span>
                 
                  </button>
                <button className="flex h-10 p-2 w-30 rounded border-1 border-slate-200 font-medium text-white  hover:bg-[#a6acaca3] bg-slate-400" onClick={()=>deleteThisRow(d.code,openDeleteModal)}>
                  <ArchiveBoxXMarkIcon className="size-6"/>
                  <span className="text-1xl hidden lg:flex md:flex">Διαγραφή</span>
                  </button>
                
              </td>
            </tr>
            )
          })}
        </tbody>
      </table>
      
      </div>
     
      <Delete open={openDeleteModal} onClose={handleDeleteClick} code={deleteRow}/>
      <Update open={openUpdateModal} onClose={handleUpdateClick} data={updateRow}/>
      </div>
     
      </div>
  )
}

export default KathgoriesEidwn