import React from 'react';
import Footer from './Footer';
import Header from './Header';
import App from '../App';
import Sales from '../components/mainactivity/Sales';
import {BrowserRouter, Routes, Route} from  'react-router-dom';

import Parastatika from '../screens/activities/Parastatika';
import EidhYphresies from '../screens/activities/EidhYphresies';
import Anafores from '../screens/activities/Anafores';
import Pelates from '../screens/activities/Pelates';
import Eshop from '../screens/activities/Eshop';
import Parametroi from '../screens/activities/Parametroi';
import Statistika from'../screens/activities/anafores/Statistika';

import HmerologioPwlhsewn from'../screens/activities/anafores/HmerologioPwlhsewn';
import ProvlepshFpa from'../screens/activities/anafores/ProvlepshFpa'; 
import ExagoghArxeiouLogisth from'../screens/activities/anafores/ExagoghArxeiouLogisth'; 
import PwlhseisAnaEidos from'../screens/activities/anafores/PwlhseisAnaEidos';
import SysxetishMyData from'../screens/activities/anafores/SysxetishMyData'; 
import YpoloipaPelatwn from'../screens/activities/anafores/YpoloipaPelatwn';

import AgoraMeParoxo from '../screens/activities/eshop/AgoraMeParoxo';
import AgoraMePos from '../screens/activities/eshop/AgoraMePos';
import AgoraMobile from '../screens/activities/eshop/AgoraMobile';
import AgoraSMSEmail from '../screens/activities/eshop/AgoraSMSEmail';
import AnanewshSyndromhs from '../screens/activities/eshop/AnanewshSyndromhs';

import ElegxosSyndromwn from '../screens/activities/parametroi/ElegxosSyndromwn';
import GenikesParametroi from '../screens/activities/parametroi/GenikesParametroi';
import KathgoriesEidwn from '../screens/activities/parametroi/KathgoriesEidwn';
import ParametroiParoxwn from '../screens/activities/parametroi/ParametroiParoxwn';
import ParametroiPos from '../screens/activities/parametroi/ParametroiPos';
import RythmiseisParastatikwn from './activities/parametroi/RythmiseisParastatikwn';
import StoixeiaEtaireias from '../screens/activities/parametroi/StoixeiaEtaireias';
import MainActivity from './activities/MainActivity';
import Addnew from './activities/parametroi/Modals/kathgorieseidwn/Addnew';

function MainScreen() {
  return (
    <div className = "w-full h-screen overflow-y-auto ">
      <Header/>
    <Routes>
    <Route path="/" element={<MainActivity/>} />
    <Route path="/parastatika" element={<Parastatika />} />
    <Route path="/pelates" element={<Pelates />} />
    <Route path="/eidh-yphresies" element={<EidhYphresies />} />
    <Route path="/anafores" >
      <Route path = "/anafores/statistika" element={<Statistika />} />
      <Route path = "/anafores/hmerologio-pwlhsewn" element = {<HmerologioPwlhsewn />} />
      <Route path = "/anafores/pwlhseis-ana-eidos" element = {<PwlhseisAnaEidos />} />
      <Route path = "/anafores/ypoloipa-pelatwn" element= {<YpoloipaPelatwn />} />
      <Route path = "/anafores/sysxetish-mydata" element = {<SysxetishMyData />} />
      <Route path = "/anafores/provlepsh-fpa" element = {<ProvlepshFpa />} />
      <Route path = "/anafores/exagogh-arxeiou-gia-logisth" element = {<ExagoghArxeiouLogisth />} />
    </Route>
    <Route path="/eshop">
    <Route path="/eshop/ananewsh-sindromis" element={<AnanewshSyndromhs />} />
    <Route path = "/eshop/agora-shndeshs-me-paroxo" element={<AgoraMeParoxo />} />
    <Route path ="/eshop/agora-syndeshs-me-pos" element={<AgoraMePos />} />
    <Route path ="/eshop/agora-adeias-mobile" element = {<AgoraMobile />} />
    <Route path ="/eshop/agora-sms-email" element ={<AgoraSMSEmail />} />
    </Route>
    <Route path="/parametroi">
    <Route path="/parametroi/stoixeia-etaireias" element={<StoixeiaEtaireias />} />
    <Route path="/parametroi/elegxos-lhxhs-syndromwn" element ={<ElegxosSyndromwn />} />
    <Route path="/parametroi/rythmiseis-parastatikwn" element ={<RythmiseisParastatikwn />} />
    <Route path ="/parametroi/parametroi-pos" element={<ParametroiPos />} />
    <Route path="/parametroi/parametroi-paroxwn" element={<ParametroiParoxwn />} />
    <Route path="/parametroi/kathgories-eidwn" element ={<KathgoriesEidwn />} />
    <Route path="/parametroi/kathgories-eidwn/:code" element={<KathgoriesEidwn />} />
    <Route path="/parametroi/genikes-parametroi" element={<GenikesParametroi />} />
    </Route>
    </Routes>
     
    </div>
    
  );
};

export default MainScreen